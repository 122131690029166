<template>
  <div class="page-card-demo  page-info-content">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getDate"
      :form-inline="formInline"
      :form-item-arr="formItemArr"
    />
    <div class="page-container-table">
      <div class="amount-add">
        <el-button type="primary" size="small" @click="handleAdd">
          录入额度
        </el-button>
      </div>
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      >
        <!-- <template #status="{ row }">
          <span>{{ row.status === 0 ? '进行中' : '已下架' }}</span>
          <span class="reason">{{ row.reason === 1 ? '(售出下架)' : row.reason === 2 ? '(超时下架)' : row.reason === 3 ? '(主动下架)': '' }}</span>
        </template> -->
      </Table>
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getDate" />
    </div>
    <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" class="dialog" width="40%">
      <el-form ref="dialogRef" :model="dialogList" :rules="dialogRule" label-width="150px">
        <el-form-item label="核心企业" prop="coreName">
          <el-select
            v-model="dialogList.coreId"
            filterable
            placeholder="核心企业"
            class="br-select-large"
            :disabled="dialogTitle!=='额度录入'"
            @change="coreEnterPriseListChange"
          >
            <el-option
              v-for="(item,index) in coreEnterPriseList"
              :key="index"
              :value="item.cmpCode"
              :label="item.cmpName"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="资金方" prop="bankName">
          <el-select
            v-model="dialogList.bankId"
            filterable
            placeholder="资金方"
            class="br-select-large"
            :disabled="dialogTitle!=='额度录入'"
            @change="startFuncListChange"
          >
            <el-option
              v-for="(item,index) in bankList"
              :key="index"
              :label="item.funderName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="集团模式" prop="groupType">
          <el-radio-group v-model="dialogList.groupType" class="br-radio-large" :disabled="dialogTitle!=='额度录入'" @change="groupTypeChange">
            <el-radio :label="1">
              是
            </el-radio>
            <el-radio :label="0">
              否
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="dialogList.groupType==1" label="集团名称" prop="group">
          <el-select
            v-model="dialogList.group"
            class="br-select-large"
            :disabled="dialogTitle!=='额度录入'"
            value-key="groupUnicode"
            @change="groupNameChange"
          >
            <el-option
              v-for="(item,index) in groupSelectList"
              :key="index"
              :label="item.groupName"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="总额度(元)" prop="quotaSum">
          <el-input
            v-model="dialogList.quotaSum"
            class="br-input-large"
            clearable
            oninput="!/^(\d+\.?)?\d{0,2}$/.test(this.value)?(this.value=this.value.substring(0, this.value.length-1)): ''"
            @input="quotaSumInput"
            @change="quotaChange"
          />
        </el-form-item>
        <el-form-item label="核心企业额度到期日" prop="quotaDue">
          <el-date-picker
            v-model="dialogList.quotaDue"
            type="date"
            placeholder="核心企业额度到期日"
            class="br-date-large"
            :picker-options="dateOptions"
            value-format="timestamp"
          />
        </el-form-item>
        <el-form-item label="供应商额度(元)" prop="defCustQuoat">
          <el-input
            v-model="dialogList.defCustQuoat"
            class="br-input-large"
            clearable
            oninput="!/^(\d+\.?)?\d{0,2}$/.test(this.value)?(this.value=this.value.substring(0, this.value.length-1)): ''"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button class="br-btn template-reset-btn" size="small" @click="dialogFormVisible = false">
          取 消
        </el-button>
        <el-button class="br-btn template-search-btn" size="small" type="primary" @click="dialogSubmit()">
          确认提交
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Pagination from '@/components/Pagination2'
import Table from '@/components/Table.vue'
import { getAmountCoreList, selectGroupList, quotaInsert } from '@/api/tradeFinancing'
export default {
  components: { FormSearch, Pagination, Table },
  data() {
    return {
      loading: false,
      total: 0,
      formInline: {
        coreName: '', // 核心企业
        bankName: '', // 资金方
        quotaStat: '',
        pageSize: 10,
        pageNum: 1
      },
      groupSelectList: [],
      listData: [],
      formItemArr: [
        { type: 'input', label: '核心企业', value: 'coreName' },
        { type: 'input', label: '资金方', value: 'bankName' },
        { type: 'select', label: '数据来源', value: 'quotaStat', pLabel: 'dictName', pValue: 'dictId', child: [{ dictId: 0, dictName: '有效' }, { dictId: 1, dictName: '失效' }] }
      ],

      itemData: [
        { label: '核心企业', prop: 'coreName', width: 220 },
        { label: '资金方', prop: 'bankName', width: 120 },
        { label: '所属集团', prop: 'groupName', width: 280 },
        { label: '总额度(元)', prop: 'quotaSum', width: 180 },
        { label: '已用额度(元)', prop: 'quotaUsed', width: 180 },
        { label: '冻结额度(元)', prop: 'quotaDisabled', width: 180 },
        { label: '可用额度(元)', prop: 'quotaAbled', width: 180 },
        { label: '额度到期日', prop: 'quotaDue', width: 180 },
        { label: '额度状态', prop: 'quotaStat', child: [{ dictName: '有效', dictId: 0 }, { dictName: '失效', dictId: 1 }], width: 180 },
        { label: '供应商额度(元)', prop: 'defCustQuoat', width: 180 }
      ],
      operationButton: [
        { bType: 'text', label: '详情', handleEvent: this.viewDetails },
        { bType: 'text', label: '额度调整', handleEvent: this.amountEdit }
      ],
      dialogTitle: '额度录入',
      dialogList: {
        id: '', // 新增不要编辑要
        bankId: '', // 资金方ID
        bankName: '', // 资金方名称
        coreId: '', // 核心企业ID
        coreName: '', // 核心企业名称
        groupType: 0, // 集团模式
        defCustDue: '', // 供应商默认到期日
        defCustQuoat: '', // 供应商默认额度
        quotaDue: '', // 额度到期日
        group: '',
        quotaSum: ''// 额度总额
      },
      dialogFormVisible: false,
      dateOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7
        }
      },
      dialogRule: {
        bankName: [{ required: true, message: '请选择资金方', trigger: 'blur' }], // 资金方名称
        coreName: [{ required: true, message: '请选择核心企业', trigger: 'blur' }], // 核心企业名称
        quotaDue: [{ required: true, message: '请选择核心企业额度到期日', trigger: 'blur' }], // 额度到期日
        groupType: [{ required: true, message: '请选择集团模式', trigger: ['blur', 'change'] }], // 集团模式
        group: [{ required: true, message: '请选择集团名称', trigger: ['blur', 'change'] }], // 集团模式
        quotaSum: [{ required: true, message: '请输入总额度', trigger: ['blur', 'change'] }, { validator: this.quotaSumValidator, trigger: 'blur' }], // 额度总额
        defCustQuoat: [{ required: true, message: '请输入供应商额度', trigger: ['blur', 'change'] }, { validator: this.defCustQuoatValidator, trigger: 'blur' }]// 供应商默认额度
      }
    }
  },
  computed: {
    coreEnterPriseList() {
      return this.$store.state.tradeFinancing.coreEnterPriseList
    },
    bankList() {
      return this.$store.state.tradeFinancing.bankList
    }
  },
  created() {
    if (!this.$store.state.tradeFinancing.coreEnterPriseList.length) {
      this.$store.dispatch('tradeFinancing/queryCmpListByCmpRoleList')
    }
    if (!this.$store.state.tradeFinancing.bankList.length) {
      this.$store.dispatch('tradeFinancing/querBankList')
    }
  },
  methods: {
    getDate(params) {
      this.loading = true
      this.formInline = {
        ...this.formInline,
        ...params
      }
      getAmountCoreList(this.formInline, res => {
        this.loading = false
        this.listData = res.data.list
        this.total = res.data.total
      }, () => {
        this.loading = false
      })
    },
    handleAdd() {
      this.dialogFormVisible = true
    },
    // 集团模式：是-显示集团名称，选择完集团名称自动带出总额度到期日供应商额度，否-隐藏集团名称
    groupTypeChange(e) {
      if (e === 0) {
        this.dialogList['group'] = ''
        this.dialogList['groupName'] = ''
        this.dialogList['groupUnicode'] = ''
        this.dialogList['quotaSum'] = ''
        this.dialogList['quotaDue'] = ''
        this.dialogList['defCustQuoat'] = ''
        this.dialogList['groupQuotaId'] = ''
        this.dialogQuotaSumMax = ''
        this.dateOptions = {
          disabledDate(time) {
            return time.getTime() < Date.now() - 8.64e7
          }
        } //  今天之后 包括今天
      }
    },
    groupNameChange(e) {
      if (e) {
        this.dialogList['groupName'] = e.groupName
        this.dialogList['groupUnicode'] = e.groupUnicode
        this.dialogList['quotaSum'] = e.quotaSum
        this.dialogList['quotaDue'] = e.quotaDue
        this.dialogList['groupQuotaId'] = e.id
        this.dialogQuotaSumMax = e.quotaSum
        this.dateOptions = {
          disabledDate(time) {
            return time.getTime() < Date.now() - 8.64e7 || time.getTime() > e.quotaDue
          }
        } // 今天之后 包括今天
        this.quotaChange(e.quotaSum)

        this.$forceUpdate()
      }
    },
    // 弹窗里--总额度quotaSum change 供应商额度自动代入
    quotaChange(e) {
      if (!this.dialogList.defCustQuoat) {
        this.dialogList.defCustQuoat = e
        this.$forceUpdate()
      }
    },

    // 弹窗里--资方查询--select change
    startFuncListChange(e) {
      this.dialogList['bankName'] = this.bankList.filter(item => {
        return item['id'] === e
      })[0]['funderName']
      const cmpunicode = this.bankList.filter(item => {
        return item['id'] === e
      })[0]['funderUnicode']
      if (this.dialogList.groupType === 1) this.groupTypeChange(0)
      this.getGroupSelectList(cmpunicode)
    },
    getGroupSelectList(bankUnicode) {
      selectGroupList({ pageNum: 1, pageSize: 1000000, bankUnicode: bankUnicode || '' }, res => {
        if (res.code === 200) {
          this.groupSelectList = res.data.list
        }
      })
    },
    coreEnterPriseListChange(e) {
      this.dialogList['coreName'] = this.coreEnterPriseList.filter(item => {
        return item['cmpCode'] === e
      })[0]['cmpName']
    },

    quotaSumInput(e) {
      if (this.dialogList.groupType === 1 && e > this.dialogQuotaSumMax) {
        this.dialogList['quotaSum'] = this.dialogQuotaSumMax
      }
    },
    // 查看详情
    viewDetails({ id }) {
      this.$router.push({
        path: '/amountManage/coreDetail',
        query: { id }
      })
    },
    amountEdit({ id }) {
      this.$router.push({
        path: '/amountManage/edit',
        query: { id }
      })
    },
    dialogSubmit() {
      this.dialogRef = this.$refs['dialogRef']
      this.dialogList.defCustDue = this.dialogList.quotaDue

      this.dialogRef.validate(valid => {
        if (valid) {
          // 供应商额度defCustQuoat<=总额度quotaSum
          if (this.dialogList.defCustQuoat * 1 > this.dialogList.quotaSum) {
            this.$message({
              type: 'error',
              message: '供应商额度必须小于等于核心企业对应该资金方的总额度'
            })
            return
          }
          const params = JSON.parse(JSON.stringify(this.dialogList))
          delete params.group
          if (this.dialogTitle === '额度录入') {
            // 新增
            delete params.id
            quotaInsert(params, res => {
              if (res.code === 200) {
                this.$message({
                  type: 'success',
                  message: this.dialogTitle + '成功'
                })
                this.getDate()
                this.dialogFormVisible = false
              }
            })
          } else {
            // 编辑
            // delete params.groupType
            // delete params.groupName
            // delete params.groupUnicode
            // this.quotaUpdate(params).then(res => {
            //   this.setLoading(0)
            //   if (res.code == 200) {
            //     this.$message({
            //       type: 'success',
            //       message: this.dialogTitle + '成功'
            //     })
            //     this.queryHxqyList(this.searchData)
            //     this.dialogFormVisible = false
            //   }
            // })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="scss">
    .amount-add {
        margin: 20px 10px;
    }
</style>
